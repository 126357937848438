import { Injectable } from "@angular/core";
import { BaseService } from "@app/shared/base/services";
import { __ } from "@app/shared/functions/object.functions";
import { IdentityToken } from "@app/shared/models/classes/IdentityToken";
import { Role } from "@app/shared/models/classes/Role";
import { Ticket } from "@app/shared/models/classes/Ticket";
import { ApplicationUser } from "@app/shared/models/classes/ApplicationUser";
import { UsersService } from "@app/shared/services/users.service";
import { environment } from "@env/environment";
import * as moment from "moment";
import { Observable, of, Subject } from "rxjs";
import { catchError, finalize, map, mergeMap } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";

const credentialsKey = "349haisadiugz82973082hdasjdi8";

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable()
export class AuthenticationService extends BaseService {
  readonly clientId = "siudfho2349!ihasd8gi3$ihsdo";

  readonly clientSecret = "as9dzho2=gu8aishd8!89h3o";
  private _credentials$: Subject<IdentityToken> = new Subject<IdentityToken>();
  credentials$: Observable<IdentityToken> = this._credentials$.asObservable();

  constructor(private httpClient: HttpClient, private usersService: UsersService) {
    super();

    const savedCredentials = localStorage.getItem(credentialsKey); // sessionStorage.getItem(credentialsKey) ||
    if (!__.IsNullOrUndefined(savedCredentials)) {
      this._credentials = JSON.parse(savedCredentials);
    }
  }

  private _credentials: IdentityToken | null;

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): IdentityToken | null {
    return this._credentials;
  }

  get user(): ApplicationUser {
    return this._credentials.user;
  }

  get ticket(): any {
    return {
      accessToken: this._credentials.access_token,
      expires: this._credentials.expires,
      refreshToken: this._credentials.refresh_token,
    };
  }

  /**
   * Authenticates the user.
   * @param body The login parameters.
   * @param remember Whether the user should stay logged in after closing the session
   * @return The user credentials.
   */
  login(body: AccessTokenRequestBody, remember?: boolean): Observable<ApplicationUser> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", `application/x-www-form-urlencoded`);

    const payload = new URLSearchParams({
      grant_type: "password",
      username: body.email,
      password: body.password,
    });
    return this.httpClient
      .disableAccessToken()
      .enableBasicAuthorization()
      .disableApiPrefix()
      .skipErrorHandler()
      .post<any>(`${environment.masterServerUrl}connect/token`, payload, { headers })
      .pipe(
        mergeMap((result: Ticket) => {
          const identityToken = {
            access_token: result.access_token,
            expires: moment().add("s", result.expires_in).toISOString(),
            refresh_token: result.refresh_token,
            user: null as ApplicationUser,
          };

          this.setCredentials(identityToken, true);

          return this.usersService.getCurrentUser().pipe(
            map((user: ApplicationUser) => {
              identityToken.user = user;

              this.setCredentials(identityToken, true);

              return user;
            })
          );
        })
      );
  }

  hasRole(group: string) {
    if (__.IsNullOrUndefinedOrEmpty(group)) {
      return true;
    }
    if (environment.testPermissions === false && environment.production !== true) {
      return true;
    }
    if (!__.IsNullOrUndefined(this.credentials.user.roles)) {
      if (this.isCompanyAdministrator()) {
        return true;
      }
      return this.credentials.user.roles.some((q) => q === group);
    }
    return false;
  }

  hasAnyRole(groups: string[]) {
    if (groups.length === 0) {
      return true;
    }
    if (environment.testPermissions === false && environment.production !== true) {
      return true;
    }
    if (!__.IsNullOrUndefined(this.credentials.user.roles)) {
      if (this.isCompanyAdministrator()) {
        return true;
      }

      return groups.findIndex((a) => this.credentials.user.roles.some((q) => q === a)) > -1;
    }
    return false;
  }

  isCompanyAdministrator(): boolean {
    return this.credentials.user.roles.indexOf(Role.CompanyAdministrator) > -1;
  }

  isAdministrator(): boolean {
    return this.credentials.user.roles.indexOf(Role.Administrator) > -1;
  }

  isAnyAdministrator(): boolean {
    return this.isAdministrator() || this.isCompanyAdministrator();
  }

  refreshToken(): Observable<Ticket> {
    let headers = new HttpHeaders();
    headers = headers.set("Content-Type", `application/x-www-form-urlencoded`);

    return this.httpClient
      .disableAccessToken()
      .enableBasicAuthorization()
      .disableApiPrefix()
      .skipErrorHandler()
      .post<any>(
        `${environment.masterServerUrl.replace(/\/api\/v\d+/, "")}connect/token`,
        `grant_type=refresh_token&refresh_token=${this.credentials.refresh_token}`,
        { headers }
      )
      .pipe(
        map((result: any) => {
          const identityToken = {
            access_token: result.access_token,
            expires: moment().add("s", result.expires_in).toISOString(),
            refresh_token: this.ticket.refreshToken,
            user: this._credentials.user,
          };

          this.setCredentials(identityToken, true);

          return result;
        })
      );
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    return this.httpClient.get(`users/logout`).pipe(
      catchError((error: any) => {
        return of(false);
      }),
      finalize(() => {
        this.setCredentials();
      }),
      map((success: boolean) => {
        return success;
      })
    );
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !__.IsNullOrUndefined(this._credentials) && !__.IsNullOrUndefined(this._credentials.user);
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials?: IdentityToken, remember?: boolean) {
    this._credentials = credentials || null;
    this._credentials$.next(this._credentials);

    if (credentials) {
      localStorage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      localStorage.removeItem(credentialsKey);
    }
  }
}

export class AccessTokenRequestBody {
  email: string;
  password: string;
}

export class AccessTokenResponse {
  access_token: string;
  expires: string;
  refresh_token: string;
}
