import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingBarComponent } from './loading-bar.component';
import { LoadingBarService } from '@app/shared/services/local/loading-bar/loading-bar.service';

@NgModule({
  imports: [CommonModule],
  declarations: [LoadingBarComponent],
  exports: [LoadingBarComponent]
})
export class LoadingBarModule {
  static forRoot(): ModuleWithProviders<LoadingBarModule> {
    return {
      ngModule: LoadingBarModule,
      providers: [LoadingBarService]
    };
  }
}
