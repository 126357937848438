import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { AccessTokenInterceptor } from '@app/core/http/access-token.interceptor';
import { AuthorizationInterceptor } from '@app/core/http/authorization.interceptor';
import { BasicAuthorizationInterceptor } from '@app/core/http/basic-authorization.interceptor';
import { LoadingInterceptor } from '@app/core/http/loading.interceptor';
import { SharedModule } from '@app/shared';
import { AuthorizedModule } from '@app/shared/components/authorized/authorized.module';
import { BreadcrumbsModule } from '@app/shared/components/breadcrumbs/breadcrumbs.module';
import { ImageModule } from '@app/shared/components/image/image.module';
import { LoadingModule } from '@app/shared/components/loading/loading.module';
import {
  FormDeactivateableDialogModule,
} from '@app/shared/route-guards/form-deactivateable/dialog/form-deactivateable-dialog.module';
import { FormDeactiviationGuard } from '@app/shared/route-guards/form-deactivateable/form-deactiviation.guard';
import { ApiUrlService } from '@app/shared/services/api-url.service';
import { ForbiddenService } from '@app/shared/services/forbidden.service';
import { ITenantService } from '@app/shared/services/itenant.service';
import { TenantService } from '@app/shared/services/tenant.service';
import { UsersService } from '@app/shared/services/users.service';

import { AuthenticationGuard } from './authentication/authentication.guard';
import { AuthenticationService } from './authentication/authentication.service';
import { ApiPrefixInterceptor } from './http/api-prefix.interceptor';
import { CacheInterceptor } from './http/cache.interceptor';
import { ErrorHandlerInterceptor } from './http/error-handler.interceptor';
import { HttpCacheService } from './http/http-cache.service';
import { HttpService } from './http/http.service';
import { I18nService } from './i18n.service';
import { BaseLayoutModule } from './layouts/base/base-layout.module';
import { RouteReusableStrategy } from './route-reusable-strategy';

@NgModule({ imports: [SharedModule,
        RouterModule,
        BreadcrumbsModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        ImageModule,
        MatMenuModule,
        MatTooltipModule,
        MatFormFieldModule,
        MatTooltipModule,
        MatInputModule,
        MatAutocompleteModule,
        LoadingModule,
        MatProgressSpinnerModule,
        AuthorizedModule,
        BaseLayoutModule,
        FormDeactivateableDialogModule], providers: [
        {
            provide: HttpClient,
            useClass: HttpService
        },
        I18nService,
        ForbiddenService,
        FormDeactiviationGuard,
        AccessTokenInterceptor, // needs to be above authentication service
        BasicAuthorizationInterceptor,
        AuthorizationInterceptor,
        AuthenticationService,
        AuthenticationGuard,
        HttpCacheService,
        ApiPrefixInterceptor,
        UsersService,
        ErrorHandlerInterceptor,
        CacheInterceptor,
        {
            provide: RouteReuseStrategy,
            useClass: RouteReusableStrategy
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry
  ) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }

    this.matIconRegistry.addSvgIconLiteral('check_circle', this.domSanitizer.bypassSecurityTrustHtml(CHECK_CIRCLE));
    this.matIconRegistry.addSvgIconLiteral('block', this.domSanitizer.bypassSecurityTrustHtml(BLOCK));
    this.matIconRegistry.addSvgIconLiteral('add_circle_outline', this.domSanitizer.bypassSecurityTrustHtml(ADD_CIRCLE_OUTLINE));
    this.matIconRegistry.addSvgIconLiteral('new', this.domSanitizer.bypassSecurityTrustHtml(NEW));
    this.matIconRegistry.addSvgIconLiteral('microphone', this.domSanitizer.bypassSecurityTrustHtml(MICROPHONE_ICON));
    this.matIconRegistry.addSvgIconLiteral('arrow_down', this.domSanitizer.bypassSecurityTrustHtml(ARROW_DOWN_ICON));
    this.matIconRegistry.addSvgIconLiteral('bar_chart', this.domSanitizer.bypassSecurityTrustHtml(BAR_CHART_ICON));
    this.matIconRegistry.addSvgIconLiteral('business_man_woman', this.domSanitizer.bypassSecurityTrustHtml(BUSINESS_MAN_WOMAN_ICON));
    this.matIconRegistry.addSvgIconLiteral('network', this.domSanitizer.bypassSecurityTrustHtml(NETWORK_ICON));
    this.matIconRegistry.addSvgIconLiteral('business_woman', this.domSanitizer.bypassSecurityTrustHtml(BUSINESS_WOMAN_ICON));
    this.matIconRegistry.addSvgIconLiteral('file', this.domSanitizer.bypassSecurityTrustHtml(FILE_ICON));
    this.matIconRegistry.addSvgIconLiteral('sound_wave', this.domSanitizer.bypassSecurityTrustHtml(SOUND_WAVE_ICON));
    this.matIconRegistry.addSvgIconLiteral('dashboard', this.domSanitizer.bypassSecurityTrustHtml(DASHBOARD_ICON));
    this.matIconRegistry.addSvgIconLiteral('consulting', this.domSanitizer.bypassSecurityTrustHtml(CONSULTING_ICON));
  }
}

const BLOCK = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-4.42 3.58-8 8-8 1.85 0 3.55.63 4.9 1.69L5.69 16.9C4.63 15.55 4 13.85 4 12zm8 8c-1.85 0-3.55-.63-4.9-1.69L18.31 7.1C19.37 8.45 20 10.15 20 12c0 4.42-3.58 8-8 8z"/></svg>`;
const ADD_CIRCLE_OUTLINE = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"/></svg>`;
const NEW = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M7.25 12.5L4.75 9H3.5v6h1.25v-3.5L7.3 15h1.2V9H7.25zM9.5 15h4v-1.25H11v-1.11h2.5v-1.26H11v-1.12h2.5V9h-4zm9.75-6v4.5h-1.12V9.99h-1.25v3.52h-1.13V9H14.5v5c0 .55.45 1 1 1h4c.55 0 1-.45 1-1V9h-1.25z"/></svg>`;
const CHECK_CIRCLE = `<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z"/></svg>`;
const MICROPHONE_ICON = `
<svg id="microphone_4" width="32" height="32" viewBox="0 0 32 32" x="1120" y="2800">
<path d="M16 27.749c-5.981 0-10.667-4.926-10.667-11.214v-4.255c0-0.294 0.24-0.533 0.533-0.533h1.6c0.293 0 0.533 0.239 0.533 0.533 0 0.293-0.24 0.533-0.533 0.533h-1.067v3.722c0 5.785 4.127 10.147 9.6 10.147s9.6-4.351 9.6-10.121v-3.748h-1.067c-0.293 0-0.533-0.24-0.533-0.533 0-0.294 0.24-0.533 0.533-0.533h1.6c0.293 0 0.533 0.239 0.533 0.533v4.283c0 6.272-4.686 11.186-10.667 11.186z"></path>
<path d="M16 25.083c-4.705 0-8.533-3.835-8.533-8.547v-7.974c0-4.729 3.828-8.575 8.533-8.575s8.533 3.809 8.533 8.492v8.083c0 4.7-3.828 8.522-8.533 8.522zM16 1.054c-4.116 0-7.467 3.369-7.467 7.508v7.974c0 4.124 3.35 7.481 7.467 7.481s7.467-3.344 7.467-7.454v-8.084c0-4.095-3.35-7.425-7.467-7.425z"></path>
<path d="M15.467 31.481c-0.293 0-0.533-0.24-0.533-0.533v-3.2c0-0.294 0.24-0.533 0.533-0.533s0.533 0.239 0.533 0.533v3.2c0 0.293-0.24 0.533-0.533 0.533z"></path>
<path d="M20.267 32.014h-9.6c-0.293 0-0.533-0.24-0.533-0.533 0-0.294 0.24-0.533 0.533-0.533h9.6c0.293 0 0.533 0.239 0.533 0.533 0 0.293-0.24 0.533-0.533 0.533z"></path>
<path d="M20.267 6.414h-8.533c-0.293 0-0.533-0.24-0.533-0.533 0-0.294 0.24-0.533 0.533-0.533h8.533c0.293 0 0.533 0.239 0.533 0.533 0 0.293-0.24 0.533-0.533 0.533z"></path>
<path d="M20.267 10.681h-8.533c-0.293 0-0.533-0.24-0.533-0.533 0-0.294 0.24-0.533 0.533-0.533h8.533c0.293 0 0.533 0.239 0.533 0.533 0 0.293-0.24 0.533-0.533 0.533z"></path>
<path d="M20.267 14.947h-8.533c-0.293 0-0.533-0.24-0.533-0.533 0-0.294 0.24-0.533 0.533-0.533h8.533c0.293 0 0.533 0.239 0.533 0.533 0 0.293-0.24 0.533-0.533 0.533z"></path>
</svg>
`;

const ARROW_DOWN_ICON = `
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg>
`;

const BAR_CHART_ICON = `
<svg id="bar_chart" width="32" height="32" viewBox="0 0 32 32" x="1176" y="280">
<path d="M2.133 0h1.067v32h-1.067v-32z"></path>
<path d="M0 29.867h32v1.067h-32v-1.067z"></path>
<path d="M15.467 27.733h-2.133c-0.294 0-0.533-0.24-0.533-0.533v-11.733c0-0.294 0.239-0.533 0.533-0.533h2.133c0.294 0 0.533 0.239 0.533 0.533v11.733c0 0.293-0.239 0.533-0.533 0.533zM13.867 26.667h1.067v-10.667h-1.067v10.667z"></path>
<path d="M9.067 27.733h-2.133c-0.294 0-0.533-0.24-0.533-0.533v-6.4c0-0.293 0.239-0.533 0.533-0.533h2.133c0.294 0 0.533 0.24 0.533 0.533v6.4c0 0.293-0.239 0.533-0.533 0.533zM7.467 26.667h1.067v-5.333h-1.067v5.333z"></path>
<path d="M21.867 27.733h-2.133c-0.293 0-0.533-0.24-0.533-0.533v-6.4c0-0.293 0.24-0.533 0.533-0.533h2.133c0.293 0 0.533 0.24 0.533 0.533v6.4c0 0.293-0.24 0.533-0.533 0.533zM20.267 26.667h1.067v-5.333h-1.067v5.333z"></path>
<path d="M28.267 27.733h-2.133c-0.293 0-0.533-0.24-0.533-0.533v-12.8c0-0.294 0.24-0.533 0.533-0.533h2.133c0.293 0 0.533 0.239 0.533 0.533v12.8c0 0.293-0.24 0.533-0.533 0.533zM26.667 26.667h1.067v-11.733h-1.067v11.733z"></path>
<path d="M5.562 19.011c-0.153 0-0.302-0.065-0.407-0.191-0.19-0.223-0.162-0.56 0.063-0.75l9.332-7.889c0.212-0.178 0.526-0.166 0.722 0.031l4.9 4.911 7.734-7.801c0.206-0.209 0.546-0.21 0.754-0.003s0.211 0.545 0.003 0.754l-8.111 8.181c-0.101 0.101-0.237 0.157-0.378 0.157v0c-0.142 0-0.277-0.057-0.378-0.156l-4.932-4.944-8.957 7.571c-0.103 0.087-0.225 0.128-0.347 0.128z"></path>
</svg>
`;

const BUSINESS_MAN_WOMAN_ICON = `
<svg id="business_manwoman" width="42" height="32" viewBox="0 0 42 32" x="1008" y="560">
<path d="M41.067 31.766c-0.293 0-0.533-0.24-0.533-0.533v-1.467c0-3.396-5.012-4.662-8.171-4.662-0.162 0-0.318-0.075-0.419-0.204l-3.036-3.862c-0.183-0.231-0.144-0.564 0.090-0.748 0.231-0.183 0.565-0.142 0.748 0.090l2.876 3.662c3.708 0.067 8.977 1.622 8.977 5.726v1.467c0.002 0.293-0.238 0.532-0.531 0.532z"></path>
<path d="M14.658 22.3c-3.968 0-7.195-4.3-7.447-8.063-0.883-0.201-1.876-0.873-1.876-1.804v-2.667c0-0.879 1.285-1.449 2.133-1.728v-2.272c0-2.545 1.778-4.474 4.155-4.532 0.484-0.201 2.544-1.002 4.645-1.002 3.267 0 5.067 1.966 5.067 5.534v2.186c1.067 0.236 1.067 1.204 1.067 1.814v2.667c0 1.168-0.476 1.621-0.949 1.783-0.198 3.913-2.932 8.083-6.794 8.083zM16.267 1.299c-2.13 0-4.297 0.946-4.318 0.955-0.067 0.031-0.141 0.046-0.215 0.046-1.854 0-3.2 1.458-3.2 3.467v2.667c0 0.239-0.159 0.449-0.389 0.513-0.802 0.225-1.653 0.644-1.75 0.862l0.006 2.625c0 0.329 0.781 0.8 1.327 0.8 0.293 0 0.533 0.239 0.533 0.533 0 3.36 2.953 7.467 6.398 7.467 3.284 0 5.738-3.941 5.738-7.467 0-0.294 0.239-0.533 0.533-0.533 0.16 0 0.404 0 0.404-0.8v-2.667c0-0.778-0.015-0.8-0.533-0.8-0.294 0-0.533-0.239-0.533-0.533v-2.667c0-3.006-1.309-4.467-4-4.467z"></path>
<path d="M28.254 20.848c-1.686 0-3.143-0.341-4.467-0.71-0.284-0.079-0.449-0.375-0.37-0.658 0.080-0.285 0.377-0.448 0.657-0.371 1.25 0.349 2.621 0.672 4.181 0.672 6.689 0 9.273-3.341 10.054-4.725-0.537-0.906-2.266-3.89-2.574-5.196-0.538-2.277-2.342-7.575-7.481-7.575-1.023 0-1.999 0.231-2.9 0.688-0.265 0.132-0.586 0.027-0.719-0.236s-0.026-0.583 0.238-0.717c1.050-0.532 2.189-0.802 3.381-0.802 4.155 0 7.26 3.060 8.518 8.397 0.265 1.123 1.971 4.099 2.603 5.15 0.094 0.157 0.103 0.351 0.020 0.514-0.651 1.308-3.383 5.57-11.142 5.57z"></path>
<path d="M0.533 31.766c-0.294 0-0.533-0.24-0.533-0.533v-1.467c0-4.144 3.811-6 7.588-6h1.689l1.625-2.873c0.145-0.258 0.47-0.348 0.726-0.202 0.256 0.147 0.347 0.47 0.202 0.729l-1.778 3.142c-0.094 0.169-0.272 0.272-0.464 0.272h-1.999c-3.008 0-6.522 1.292-6.522 4.933v1.467c0 0.292-0.239 0.531-0.533 0.531z"></path>
<path d="M29.333 31.766c-0.293 0-0.533-0.24-0.533-0.533v-1.467c0-3.642-3.467-4.933-6.436-4.933h-2c-0.19 0-0.365-0.1-0.461-0.265l-1.957-3.348c-0.148-0.253-0.063-0.579 0.191-0.731 0.253-0.151 0.58-0.066 0.731 0.193l1.802 3.083h1.694c3.734 0 7.503 1.855 7.503 6v1.467c0.001 0.295-0.239 0.534-0.532 0.534z"></path>
<path d="M12.255 30.3c-0.032 0-0.066-0.002-0.097-0.009-0.173-0.031-0.319-0.149-0.39-0.308l-2.533-5.7c-0.094-0.212-0.041-0.462 0.132-0.614 0.173-0.156 0.423-0.179 0.624-0.066l5.2 3.035c0.143 0.082 0.239 0.229 0.26 0.393 0.021 0.162-0.035 0.329-0.153 0.444l-2.667 2.667c-0.1 0.105-0.237 0.16-0.377 0.16zM10.879 25.358l1.548 3.482 1.629-1.628-3.177-1.854z"></path>
<path d="M17.587 30.3c-0.14 0-0.276-0.057-0.378-0.156l-2.667-2.667c-0.121-0.118-0.176-0.289-0.15-0.461 0.026-0.167 0.132-0.314 0.284-0.391l5.444-2.799c0.203-0.106 0.457-0.067 0.62 0.096 0.164 0.163 0.203 0.414 0.099 0.62l-2.778 5.467c-0.078 0.153-0.223 0.258-0.391 0.286-0.028 0.003-0.055 0.005-0.083 0.005zM15.814 27.239l1.63 1.629 1.697-3.34-3.327 1.711z"></path>
</svg>
`

const NETWORK_ICON = `
<svg id="network" width="32" height="32" viewBox="0 0 32 32" x="896" y="2968">
<path d="M22.382 5.267h-11.733c-0.294 0-0.533-0.239-0.533-0.533s0.239-0.533 0.533-0.533h11.733c0.294 0 0.533 0.239 0.533 0.533s-0.238 0.533-0.533 0.533z"></path>
<path d="M24.006 26.067c-0.085 0-0.169-0.020-0.247-0.062-0.26-0.138-0.361-0.461-0.223-0.721l5.898-11.2c0.137-0.261 0.461-0.359 0.72-0.223 0.26 0.138 0.361 0.46 0.223 0.72l-5.898 11.201c-0.097 0.182-0.281 0.285-0.473 0.285z"></path>
<path d="M8.176 25.558c-0.192 0-0.378-0.106-0.473-0.287l-5.605-10.691c-0.137-0.261-0.036-0.583 0.225-0.72 0.259-0.137 0.582-0.036 0.72 0.224l5.604 10.691c0.137 0.262 0.036 0.583-0.225 0.721-0.079 0.042-0.164 0.062-0.246 0.062z"></path>
<path d="M15.571 25.534h-0.201c-2.74 0-4.698-2.628-4.909-5.15-1.188-0.393-1.947-2.084-1.947-3.251v-1.333c0-0.61 0-1.579 1.067-1.814v-1.786c0-2.237 1.245-3.751 3.108-3.801 0.37-0.128 0.755-0.273 1.15-0.421 1.397-0.527 2.843-1.071 4.131-1.071 2.22 0 3.345 1.646 3.345 4.892v2.375c0.397 0.279 1.067 0.864 1.067 1.625v1.333c0 1.181-0.822 2.863-2.022 3.251-0.208 2.524-2.116 5.151-4.789 5.151zM17.971 7.974c-1.093 0-2.446 0.51-3.756 1.003-0.433 0.163-0.858 0.323-1.262 0.461-0.055 0.019-0.113 0.028-0.172 0.028-1.573 0-2.133 1.412-2.133 2.734v2.267c0 0.294-0.239 0.533-0.533 0.533-0.518 0-0.533 0.022-0.533 0.8v1.333c0 0.949 0.702 2.268 1.394 2.268 0.294 0 0.533 0.24 0.533 0.533 0 2.14 1.651 4.533 3.861 4.533h0.201c2.204 0 3.74-2.387 3.74-4.533 0-0.293 0.239-0.533 0.533-0.533 0.695 0 1.472-1.353 1.472-2.268v-1.333c0-0.272-0.469-0.686-0.795-0.868-0.169-0.094-0.272-0.272-0.272-0.465v-2.667c-0.001-3.391-1.218-3.825-2.278-3.825z"></path>
<path d="M26.965 31.934h-22.729c-0.294 0-0.533-0.24-0.533-0.533 0-4.279 2.987-7.267 7.263-7.267 0.294 0 0.533 0.239 0.533 0.533 0 0.293-0.239 0.533-0.533 0.533-2.954 0-5.932 1.756-6.179 5.667h21.628c-0.234-3.402-2.708-5.733-6.178-5.733-0.294 0-0.533-0.24-0.533-0.533s0.239-0.533 0.533-0.533c4.276 0 7.263 3.017 7.263 7.333-0.001 0.293-0.238 0.533-0.534 0.533z"></path>
<path d="M6.905 1.134c1.473 0 2.667 1.195 2.667 2.667 0 1.474-1.194 5.717-2.667 5.717-1.472 0-2.667-4.243-2.667-5.717 0-1.473 1.193-2.667 2.667-2.667zM6.905 0.067c-2.059 0-3.733 1.675-3.733 3.733 0 1.391 1.157 6.784 3.733 6.784s3.733-5.393 3.733-6.784c0-2.060-1.676-3.733-3.733-3.733v0z"></path>
<path d="M0.581 14.511c-0.294 0-0.533-0.239-0.533-0.533 0-2.202 2.113-4.445 4.188-4.445h2.145c0.294 0 0.533 0.239 0.533 0.533s-0.239 0.533-0.533 0.533h-2.144c-1.489 0-3.121 1.767-3.121 3.378-0.001 0.294-0.239 0.533-0.534 0.533z"></path>
<path d="M-0.018 13.801h6.4v1.067h-6.4v-1.067z"></path>
<path d="M25.571 1.134c1.473 0 2.667 1.195 2.667 2.667 0 1.474-1.194 5.717-2.667 5.717s-2.667-4.243-2.667-5.717c0-1.473 1.194-2.667 2.667-2.667zM25.571 0.067c-2.059 0-3.733 1.675-3.733 3.733 0 1.391 1.157 6.784 3.733 6.784s3.733-5.393 3.733-6.784c0-2.060-1.676-3.733-3.733-3.733v0z"></path>
<path d="M31.485 14.334c-0.294 0-0.533-0.239-0.533-0.533 0-1.51-1.162-3.2-2.714-3.2h-2.655c-0.294 0-0.533-0.239-0.533-0.533s0.239-0.533 0.533-0.533h2.655c2.228 0 3.78 2.249 3.78 4.267 0 0.293-0.239 0.533-0.533 0.533z"></path>
<path d="M25.582 13.801h6.4v1.067h-6.4v-1.067z"></path>
</svg>
`;

const BUSINESS_WOMAN_ICON = `
<svg id="business_woman" width="32" height="32" viewBox="0 0 32 32" x="1176" y="560">
<path d="M29.867 31.266c-0.293 0-0.533-0.239-0.533-0.533v-1.467c0-3.397-5.012-4.662-8.171-4.662-0.162 0-0.318-0.074-0.419-0.203l-3.036-3.862c-0.183-0.231-0.143-0.566 0.090-0.75 0.233-0.181 0.566-0.142 0.748 0.090l2.877 3.662c3.707 0.069 8.977 1.62 8.977 5.726v1.467c0.001 0.294-0.239 0.533-0.532 0.533z"></path>
<path d="M2.133 31.266c-0.294 0-0.533-0.239-0.533-0.533v-1.467c0-4.106 5.188-5.655 8.837-5.726l2.906-3.663c0.182-0.231 0.518-0.271 0.749-0.087s0.27 0.518 0.086 0.75l-3.063 3.862c-0.101 0.127-0.255 0.201-0.418 0.201-3.105 0-8.030 1.266-8.030 4.662v1.467c0 0.295-0.239 0.534-0.533 0.534z"></path>
<path d="M15.722 20.466c-7.729 0-10.483-4.308-11.143-5.628-0.081-0.163-0.074-0.356 0.019-0.512 0.635-1.060 2.34-4.058 2.605-5.182 1.263-5.345 4.367-8.411 8.519-8.411s7.257 3.066 8.519 8.411c0.265 1.125 1.97 4.122 2.604 5.182 0.093 0.156 0.1 0.35 0.019 0.512-0.66 1.318-3.413 5.628-11.142 5.628zM5.666 14.613c0.786 1.398 3.385 4.786 10.055 4.786s9.267-3.388 10.055-4.786c-0.534-0.91-2.267-3.919-2.575-5.225-0.539-2.282-2.347-7.589-7.481-7.589s-6.942 5.308-7.481 7.589c-0.308 1.303-2.041 4.315-2.575 5.225z"></path>
<path d="M13.055 31.133c-0.032 0-0.066-0.002-0.097-0.009-0.173-0.032-0.319-0.149-0.39-0.308l-2.667-6c-0.095-0.214-0.039-0.466 0.138-0.62 0.178-0.155 0.434-0.174 0.633-0.049l5.333 3.332c0.138 0.086 0.228 0.23 0.246 0.393 0.019 0.161-0.037 0.322-0.153 0.437l-2.667 2.667c-0.1 0.101-0.237 0.157-0.377 0.157zM11.583 25.975l1.644 3.697 1.644-1.644-3.287-2.053z"></path>
<path d="M18.387 31.133c-0.14 0-0.277-0.055-0.378-0.157l-2.667-2.667c-0.114-0.113-0.172-0.275-0.153-0.437 0.018-0.16 0.11-0.306 0.245-0.391l5.333-3.333c0.201-0.123 0.455-0.105 0.633 0.050 0.177 0.154 0.234 0.404 0.138 0.619l-2.667 6c-0.070 0.16-0.217 0.275-0.389 0.308-0.031 0.005-0.064 0.009-0.096 0.009zM16.572 28.028l1.644 1.644 1.644-3.697-3.287 2.053z"></path>
</svg>
`;

const FILE_ICON = `
<svg id="file" width="32" height="32" viewBox="0 0 32 32" x="280" y="1624">
<path d="M27.733 32h-23.467c-0.294 0-0.533-0.24-0.533-0.533v-23.467c0-0.142 0.057-0.277 0.156-0.378l7.467-7.467c0.1-0.1 0.236-0.156 0.378-0.156h16c0.293 0 0.533 0.239 0.533 0.533v30.933c0 0.293-0.24 0.533-0.533 0.533zM4.8 30.933h22.4v-29.867h-15.246l-7.154 7.154v22.713z"></path>
<path d="M11.733 8.533h-6.933c-0.294 0-0.533-0.239-0.533-0.533s0.239-0.533 0.533-0.533h6.4v-6.4c0-0.294 0.239-0.533 0.533-0.533s0.533 0.239 0.533 0.533v6.933c0 0.294-0.239 0.533-0.533 0.533z"></path>
</svg>
`;

const SOUND_WAVE_ICON = `
<svg id="sound_wave" width="33" height="32" viewBox="0 0 33 32" x="784" y="3920">
<path d="M0.533 20.8c-0.293 0-0.533-0.24-0.533-0.533v-8.533c0-0.293 0.24-0.533 0.533-0.533s0.533 0.24 0.533 0.533v8.533c0 0.293-0.24 0.533-0.533 0.533z"></path>
<path d="M4.8 24c-0.293 0-0.533-0.24-0.533-0.533v-16c0-0.293 0.24-0.533 0.533-0.533s0.533 0.24 0.533 0.533v16c0 0.293-0.24 0.533-0.533 0.533z"></path>
<path d="M16.533 20.8c-0.293 0-0.533-0.24-0.533-0.533v-8.533c0-0.293 0.24-0.533 0.533-0.533s0.533 0.24 0.533 0.533v8.533c0 0.293-0.24 0.533-0.533 0.533z"></path>
<path d="M13.333 22.933c-0.293 0-0.533-0.24-0.533-0.533v-12.8c0-0.293 0.24-0.533 0.533-0.533s0.533 0.24 0.533 0.533v12.8c0 0.293-0.24 0.533-0.533 0.533z"></path>
<path d="M9.067 27.2c-0.293 0-0.533-0.24-0.533-0.533v-21.333c0-0.293 0.24-0.533 0.533-0.533s0.533 0.24 0.533 0.533v21.333c0 0.293-0.24 0.533-0.533 0.533z"></path>
<path d="M20.8 22.933c-0.293 0-0.533-0.24-0.533-0.533v-12.8c0-0.293 0.24-0.533 0.533-0.533s0.533 0.24 0.533 0.533v12.8c0 0.293-0.24 0.533-0.533 0.533z"></path>
<path d="M32.533 20.8c-0.293 0-0.533-0.24-0.533-0.533v-8.533c0-0.293 0.24-0.533 0.533-0.533s0.533 0.24 0.533 0.533v8.533c0 0.293-0.24 0.533-0.533 0.533z"></path>
<path d="M29.333 22.933c-0.293 0-0.533-0.24-0.533-0.533v-12.8c0-0.293 0.24-0.533 0.533-0.533s0.533 0.24 0.533 0.533v12.8c0 0.293-0.24 0.533-0.533 0.533z"></path>
<path d="M25.067 26.133c-0.293 0-0.533-0.24-0.533-0.533v-19.2c0-0.293 0.24-0.533 0.533-0.533s0.533 0.24 0.533 0.533v19.2c0 0.293-0.24 0.533-0.533 0.533z"></path>
</svg>
`;

const DASHBOARD_ICON = `
<svg id="dashboard" width="32" height="32" viewBox="0 0 32 32" x="112" y="1120">
<path d="M30.667 23.867h-29.333c-0.293 0-0.533-0.24-0.533-0.533 0-9.091 6.109-15.2 15.2-15.2 9.090 0 15.2 6.109 15.2 15.2 0 0.293-0.238 0.533-0.533 0.533zM1.875 22.8h28.252c-0.227-8.282-5.721-13.6-14.127-13.6s-13.9 5.318-14.125 13.6z"></path>
<path d="M19.2 22.8h-1.067c0-1.178-0.956-2.133-2.133-2.133s-2.133 0.956-2.133 2.133h-1.067c0-1.764 1.436-3.2 3.2-3.2 1.763 0 3.2 1.436 3.2 3.2z"></path>
<path d="M14.573 21.086c-0.158 0-0.317-0.070-0.422-0.205l-3.962-5.086c-0.18-0.233-0.139-0.567 0.093-0.749 0.233-0.18 0.566-0.139 0.749 0.093l3.962 5.084c0.18 0.234 0.139 0.569-0.094 0.75-0.096 0.075-0.21 0.113-0.325 0.113z"></path>
<path d="M5.473 21.028c-0.038 0-0.077-0.003-0.115-0.013l-1.301-0.287c-0.288-0.064-0.468-0.348-0.406-0.636 0.064-0.288 0.346-0.47 0.636-0.406l1.302 0.288c0.288 0.063 0.468 0.347 0.406 0.636-0.058 0.249-0.278 0.418-0.522 0.418z"></path>
<path d="M7.771 16.39c-0.122 0-0.244-0.042-0.345-0.127l-1.013-0.862c-0.225-0.191-0.252-0.528-0.062-0.752 0.189-0.224 0.528-0.253 0.751-0.061l1.013 0.862c0.225 0.191 0.252 0.527 0.062 0.752-0.105 0.125-0.254 0.188-0.406 0.188z"></path>
<path d="M11.975 13.371c-0.212 0-0.413-0.128-0.496-0.336l-0.492-1.236c-0.111-0.273 0.022-0.583 0.298-0.692 0.273-0.109 0.583 0.025 0.69 0.299l0.493 1.235c0.111 0.273-0.022 0.583-0.297 0.692-0.064 0.027-0.132 0.038-0.196 0.038z"></path>
<path d="M17.104 12.672c-0.020 0-0.038-0.001-0.060-0.003-0.291-0.032-0.505-0.295-0.471-0.588l0.145-1.323c0.032-0.292 0.289-0.505 0.589-0.473 0.291 0.032 0.505 0.295 0.471 0.588l-0.145 1.323c-0.029 0.273-0.26 0.476-0.529 0.476z"></path>
<path d="M21.963 14.459c-0.102 0-0.206-0.030-0.298-0.092-0.244-0.165-0.308-0.497-0.144-0.74l0.748-1.104c0.164-0.244 0.497-0.308 0.739-0.143 0.244 0.165 0.307 0.497 0.144 0.74l-0.748 1.104c-0.102 0.153-0.271 0.235-0.442 0.235z"></path>
<path d="M25.419 18.31c-0.192 0-0.377-0.103-0.47-0.284-0.139-0.26-0.039-0.583 0.221-0.721l1.178-0.623c0.262-0.14 0.585-0.039 0.722 0.221 0.139 0.26 0.039 0.583-0.221 0.721l-1.178 0.623c-0.081 0.042-0.169 0.063-0.252 0.063z"></path>
</svg>
`;

const CONSULTING_ICON = `
<svg id="consulting" width="32" height="32" viewBox="0 0 32 32" x="448" y="1008">
<path d="M22.038 29.995h-3.372v-1.067h3.372c0.734 0 1.962-1.066 1.962-1.703v-8.963h1.067v8.963c0 1.312-1.804 2.77-3.028 2.77z"></path>
<path d="M24.385 15.248c-0.204 0-0.398-0.115-0.487-0.314-0.373-0.827-0.601-1.44-0.698-1.874-0.54-2.441-2.35-8.116-7.479-8.116s-6.939 5.676-7.479 8.116c-0.022 0.1-0.027 0.214-0.037 0.338-0.023 0.325-0.053 0.73-0.249 1.181-0.116 0.27-0.427 0.396-0.701 0.278-0.27-0.115-0.395-0.43-0.278-0.701 0.124-0.286 0.144-0.565 0.164-0.836 0.013-0.18 0.026-0.345 0.060-0.49 1.26-5.69 4.365-8.955 8.521-8.955s7.262 3.264 8.522 8.954c0.054 0.244 0.206 0.734 0.627 1.666 0.123 0.269 0.002 0.585-0.267 0.706-0.069 0.031-0.144 0.046-0.218 0.046z"></path>
<path d="M24.533 12.395c-0.293 0-0.533-0.239-0.533-0.533v-2.938c0-4.403-3.587-7.985-8-7.985-4.412 0-8 3.582-8 7.985v1.871c0 0.294-0.239 0.533-0.533 0.533s-0.533-0.239-0.533-0.533v-1.871c0-4.991 4.067-9.052 9.067-9.052 5.001 0 9.067 4.061 9.067 9.052v2.938c0 0.294-0.24 0.533-0.533 0.533z"></path>
<path d="M15.467 26.23c-3.53 0-6.4-3.193-6.4-7.117v-4.052c0-0.294 0.239-0.533 0.533-0.533s0.533 0.239 0.533 0.533v4.052c0 3.393 2.342 6.051 5.333 6.051 2.941 0 5.333-2.715 5.333-6.051v-5.119c0-0.294 0.24-0.533 0.533-0.533s0.533 0.239 0.533 0.533v5.119c0 3.924-2.87 7.117-6.4 7.117z"></path>
<path d="M7.467 19.328h-2.412c-1.428 0-2.388-0.911-2.388-2.267v-4c0-1.356 0.96-2.267 2.388-2.267h2.412c0.294 0 0.533 0.239 0.533 0.533v7.467c0 0.294-0.239 0.533-0.533 0.533zM5.055 11.861c-0.493 0-1.322 0.156-1.322 1.2v4c0 1.044 0.828 1.2 1.322 1.2h1.878v-6.4h-1.878z"></path>
<path d="M26.387 19.328h-1.854c-0.293 0-0.533-0.239-0.533-0.533v-7.467c0-0.294 0.24-0.533 0.533-0.533h1.854c1.447 0 2.946 0.848 2.946 2.267v4c0 1.419-1.498 2.267-2.946 2.267zM25.067 18.261h1.321c0.89 0 1.879-0.493 1.879-1.2v-4c0-0.707-0.99-1.2-1.879-1.2h-1.321v6.4z"></path>
<path d="M17.721 32.128h-4c-1.068 0-1.867-1.126-1.867-2.133s0.799-2.133 1.867-2.133h4c1.069 0 1.867 1.126 1.867 2.133s-0.798 2.133-1.867 2.133zM13.722 28.928c-0.381 0-0.8 0.557-0.8 1.067s0.419 1.067 0.8 1.067h4c0.381 0 0.8-0.557 0.8-1.067s-0.419-1.067-0.8-1.067h-4z"></path>
<path d="M23.555 14.928c-1.739 0-4.88-2.125-6.276-4.138-1.779 4.013-9.47 4.138-9.557 4.138-0.294 0-0.533-0.239-0.533-0.533s0.239-0.533 0.533-0.533c0.089 0 8.855-0.209 8.855-4.857 0-0.294 0.24-0.533 0.533-0.533s0.533 0.239 0.533 0.533c0 1.542 4.033 4.857 5.911 4.857 0.294 0 0.533 0.239 0.533 0.533s-0.239 0.533-0.533 0.533z"></path>
</svg>
`;